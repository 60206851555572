<template>
  <v-dialog v-model="dialog" width="400">
    <v-card>
      <v-card-title>Añadir Pago Parcial </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <form
        action
        data-vv-scope="partial-form"
        @submit.prevent="validateForm('partial-form')"
      >
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <label>Método de pago</label>
              <v-select
                outlined
                dense
                hide-details
                v-model="formData.method"
                :items="methods"
                data-vv-validate-on="blur"
                v-validate="'required'"
                :error-messages="errors.collect('partial-form.method')"
                data-vv-name="method"
              ></v-select>
            </v-col>
            <v-col cols="12" class="py-0">
              <label>Cantidad</label>
              <v-text-field
                outlined
                dense
                hide-details
                v-model="formData.quantity"
                data-vv-validate-on="blur"
                v-validate="'required|min:2'"
                :error-messages="errors.collect('partial-form.quantity')"
                data-vv-name="quantity"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            @click="
              dialog = false;
              files = [];
            "
            style="height: 25px; width: 100px"
            elevation="0"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            type="submit"
            style="
              width: 100px !important;
              color: #363533;
              height: 25px;
              padding-top: 10px !important;
            "
            elevation="0"
          >
            <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
            {{ $t("save", { name: $t("") }) }}
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "NewPartialPayment",
  props: ["appointment", "appointmentDates", "user_id"],
  data() {
    return {
      dialog: false,
      formData: {},
      methods: [
        {
          text: this.$t("expenses.cash"),
          value: "cash",
        },
        {
          text: this.$t("expenses.card"),
          value: "card",
        },
      ],
    };
  },
  methods: {
    ...mapActions("expenses", ["addPartialExpenses"]),
    async validateForm(scope) {
      this.errors.clear();
      let result = await this.$validator.validateAll(scope);
      this.formData.date = this.appointmentDates;
      this.formData.user_id = this.user_id;
      if (result) {
        console.log("VALIDADO");
        this.formData.user_id = this.user_id;
        console.log(this.formData);
        this.addPartialExpenses({
          appointment_id: this.appointment.id,
          formData: this.formData,
        }).then((response) => {
          this.$emit("update");
          this.$emit("close");
          console.log(response);
          if (response.success) this.dialog = false;
          else this.$alert("HAS PUESTO UNA CANTIDAD DEMASIADO ALTA");
        });
      }
    },
  },
};
</script>
<style lang="sass" scoped>
label
  line-height: 1
  height: unset
</style>
